import React from 'react'
import cx from 'classnames'
import styles from './Divider.module.scss'

interface DividerProps {
  height: 1 | 2 | 3
  hideRule: boolean
}

const Divider = ({ height = 2, hideRule = false }) => (
  <hr className={cx([styles['divider'], styles[`divider--height-${height}`], styles[hideRule ? 'divider--no-rule' : '']])} />
)

export default Divider
